import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import FooterContainer from '../common/FooterContainer';
import HeaderContainer from '../common/HeaderContainer';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GlobalDispatchContext } from "../context/Context"
import { STOP_LOADING, START_LOADING, SUPPORT_COLLECTIONS } from '../common/Constants';
import HeaderNew from '../common/HeaderNew';


function ExpandContainer(props) {



    return (
        <>
            {/* <HeaderContainer></HeaderContainer> */}
            <HeaderNew></HeaderNew>

            <div className="container-fluid container_home">
                <div className='container'>
                    <div className="pagetitle" style={{ padding: '20px' }}>
                        <h1>Attribute</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li className="breadcrumb-item active">Expand Attribute</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <FooterContainer></FooterContainer>
        </>
    );
}

export default ExpandContainer;