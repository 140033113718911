
import {
    LOGIN_SUCCESS,
    LOGOUT,
    APP_TOKEN,
    USER_TOKEN,
    SET_COLLECTION,
    SET_ASSET_COLLECTION,
    ACDMY_COLLECTION,
    ASSET_COLLECTION,
    USER_DATA,
    TWITTER_USER
} from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage"
import { ManageSessionStorage } from "../core/SessionStorage"




let applicationToken = ManageLocalStorage.get(APP_TOKEN) ? ManageLocalStorage.get(APP_TOKEN) : null
export const loginInitialState = {
    applicationToken: applicationToken,
    TWITTER_USER: ManageLocalStorage.get(TWITTER_USER) ? JSON.parse(ManageLocalStorage.get(TWITTER_USER)) : {},
    ACDMY_COLLECTION: ManageLocalStorage.get(ACDMY_COLLECTION) ? JSON.parse(ManageLocalStorage.get(ACDMY_COLLECTION)) : [{
        "code": "DAA",
        "name": "Degen Apes"
    }],
    ASSET_COLLECTION: ManageLocalStorage.get(ASSET_COLLECTION) ? JSON.parse(ManageLocalStorage.get(ASSET_COLLECTION)) : [],
    isLoggedIn: ManageLocalStorage.get(USER_DATA)
        ? true
        : false,
    userData: ManageLocalStorage.get(USER_DATA) ? JSON.parse(ManageLocalStorage.get(USER_DATA)) : undefined
};



export const LoginReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return loginInitialState
    }
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                userData: action.payload
            };

        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                userData: null,
            };
        case SET_COLLECTION:
            return {
                ...state,
                ACDMY_COLLECTION: action.payload
            }
        case SET_ASSET_COLLECTION:
            return {
                ...state,
                ASSET_COLLECTION: action.payload
            }
        case TWITTER_USER:
            ManageLocalStorage.set(TWITTER_USER, action.payload)
            console.log(">>>>>", action.payload);
            return {
                ...state,
                TWITTER_USER: action.payload
            }
        default:
            return state;
    }
};