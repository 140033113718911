import React, { useState, useEffect, useRef } from 'react';
import HeaderNew from '../common/HeaderNew'
import { playFabGetLeaderBoardAround, playFabLoginWithCustomID, playFabGetLeaderBoardTopten } from './LeaderBoardService';
import { START_LOADING, STOP_LOADING, PLAY_FAV_CONSTANTS, RENDER_URL } from '../common/Constants';
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { Card } from 'primereact/card';

function LeaderboardContainer(props) {


    const dispatch = React.useContext(GlobalDispatchContext)
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;

    const [LeaderboardValuesList, setLeaderBoardValues] = useState(null);

    const [showNoData, setShowNoData] = useState(false);

    const [PlayfabId, setPlayfabId] = useState("");

    const [CurrentuserDetails, setCurrentuserDetails] = useState(undefined)

    useEffect(() => {


        if (LeaderboardValuesList) {
            if (LeaderboardValuesList.length <= 0) {
                setShowNoData(true);
            }
        }


    }, [LeaderboardValuesList])

    useEffect(() => {

        dispatch({
            type: START_LOADING,
        });

        console.log("runs only once since its empty use-effects");

        const jsonData = {
            TitleId: PLAY_FAV_CONSTANTS.TITLE_ID,
            CustomId: userData.uuid.replace(/['"]/g, '')
        }

        playFabLoginWithCustomID(jsonData).then((response) => {



            let auth = {
                'session': response.data.data.SessionTicket

            }
            let pfid = response.data.data.PlayFabId

            setPlayfabId(pfid)

            playFabGetLeaderBoardAround(auth).then((response) => {

                var result = response.data.data.Leaderboard.filter(element => {

                    if (element.PlayFabId == pfid) {
                        return true;
                    }
                    else {
                        return false;
                    }

                });
                setCurrentuserDetails(result[result.length - 1])
                let value = response.data.data.Leaderboard;
                setLeaderBoardValues(value)

                playFabGetLeaderBoardTopten(auth).then((response) => {
                    const Arr1 = value;
                    const Arr2 = response.data.data.Leaderboard;
                    const Arr3 = Arr2.concat(Arr1);
                    setLeaderBoardValues(Arr3);
                    dispatch({
                        type: STOP_LOADING,
                    });
                });
            })
                .catch((error) => {
                    dispatch({
                        type: STOP_LOADING,
                    });
                    console.log(error);
                    setLeaderBoardValues([]);
                })




        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            console.log("Error", error);
            setLeaderBoardValues([]);
        })
    }, [])

    const renderKills = (staticArray, isHeader) => {

        try {
            return staticArray.map((value, index) => {
                if (value.Name == "Kills" && isHeader) {
                    return <span className='score_num'>{value.Value}</span>
                } else if (value.Name == "Kills") {
                    return value.Value
                }
            })
        } catch (error) {
            console.log("error", error);
        }




    }
    const renderAssists = (staticArray, isHeader) => {
        try {
            return staticArray.map((value, index) => {
                if (value.Name == "Assists" && isHeader) {
                    return <span className='score_num'>{value.Value}</span>
                } else if (value.Name == "Assists") {
                    return value.Value
                }
            })
        } catch (error) {

        }

    }
    const renderSpawn = (staticArray, isHeader) => {
        try {
            return staticArray.map((value, index) => {
                if (value.Name == "Spawns" && isHeader) {
                    return <span className='score_num'>{value.Value}</span>
                } else if (value.Name == "Spawns") {
                    return value.Value
                }
            })
        } catch (error) {

        }

    }

    const rankList = (LeaderboardValuesList) => {
        return LeaderboardValuesList.map((value, index) => {
            let AssistsValue = value.Profile.Statistics;
            let rank = value.Position + 1;
            let serielNo = index + 1;
            if (serielNo == 10) {
                return <><tr className={(PlayfabId == value.PlayFabId) ? "active_user" : "non_active"}>
                    <th scope="row">{rank}</th>
                    <td>{value.DisplayName}</td>
                    <td>{renderKills(AssistsValue)}</td>
                    <td>{renderAssists(AssistsValue)}</td>
                    <td>{renderSpawn(AssistsValue)}</td>
                </tr>
                    <tr>
                        <td colSpan="5" style={{ padding: '0px' }}>
                            <div className='u_line'></div>
                        </td>
                    </tr>

                </>
            }
            else {
                return <tr className={(PlayfabId == value.PlayFabId) ? "active_user" : "non_active"} >
                    <th scope="row">{rank}</th>
                    <td>{value.DisplayName}</td>
                    <td>{renderKills(AssistsValue)}</td>
                    <td>{renderAssists(AssistsValue)}</td>
                    <td>{renderSpawn(AssistsValue)}</td>
                </tr>;
            }



        })
    }

    return (
        <>
            <div className='section_leaderboard'>
                <HeaderNew active={RENDER_URL.LEADER_BOARD}></HeaderNew>
                <section>
                    <div className="container">
                        {/* <div className="row">
                            <h1 className="heading">LEADERBOARD</h1>
                        </div> */}
                        <div className="row mt-6">

                            {CurrentuserDetails &&
                                <div className='col-lg-4'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <h6 className="h6 head_font" style={{ float: 'left', visibility: 'hidden' }}> MY   RANKING</h6>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <table className=" table table-dark tbl-color">
                                            <thead>
                                                <tr>
                                                    <th colSpan="3" scope="col">{CurrentuserDetails.DisplayName}</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="3"><img src="./assets/img/ranking.png" />RANK <span className='rank_ind'>{CurrentuserDetails.Position + 1}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>KILLS</td>
                                                    <td>ASSISTS</td>
                                                    <td>SPAWNS</td>
                                                </tr>
                                                <tr>
                                                    <td>{renderKills(CurrentuserDetails.Profile.Statistics, true)}</td>
                                                    <td>{renderAssists(CurrentuserDetails.Profile.Statistics, true)}</td>
                                                    <td>{renderSpawn(CurrentuserDetails.Profile.Statistics, true)}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            }

                            {LeaderboardValuesList && LeaderboardValuesList.length > 0 ? <div className='col-lg-8' style={{ maxHeight: '300px' }}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <h6 className="h6 head_font small_head" style={{ float: 'left' }}> GLOBAL RANKING</h6>
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className='table_scroll'>
                                        <table style={{ width: '100%' }} className="table-hover table table-dark tbl-color">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">PLAYER</th>
                                                    <th scope="col">KILLS</th>
                                                    <th scope='col'>ASSISTS</th>
                                                    <th scope="col">SPAWNS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rankList(LeaderboardValuesList)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : <></>
                            }

                        </div>
                        {
                            showNoData &&
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 offset-lg-3' >
                                    <div className="card">
                                        <Card>
                                            <p className="m-0 text-center">
                                                <h3>Your Degen Royale leaderboard</h3>
                                                <b>No data found</b>
                                            </p>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
                <br></br>
                <br></br>
            </div>
        </>
    );
}


export default LeaderboardContainer;