import axios from "axios";
import { API_URL, PLAY_FAB_URL, PLAY_FAV_CONSTANTS } from "../common/Constants"

export const playFabLoginWithCustomID = params => {

   

    return axios.post(PLAY_FAB_URL.LOGIN_CUSTOM_ID, JSON.stringify(params));

};



export const playFabGetLeaderBoardAround = params => {

    const jsonData = {
        StatisticName: "Kills",
        ProfileConstraints:{
            ShowStatistics: "true",
            ShowDisplayName:"true"
        }
        
    }

    const auth = params;

    console.log(auth);

    return axios.post(PLAY_FAB_URL.LEADER_BOARD_AROUND, JSON.stringify(jsonData),{
        headers:{"X-Authorization":`${auth.session}`}
    });

};

export const playFabGetLeaderBoardTopten = params => {

    const jsonData = {
        StatisticName: "Kills",
        StartPostion:0,
        ProfileConstraints:{
            ShowStatistics: "true",
            ShowDisplayName:"true"
        }
        
    }

    

    const auth=params;

    return axios.post(PLAY_FAB_URL.LEADER_BOARD_TOPTEN, JSON.stringify(jsonData),{
        headers:{"X-Authorization":`${auth.session}`}
    });

};




