import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import ContentEditable from 'react-contenteditable'
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { GlobalDispatchContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING, SET_ASSET_COLLECTION, ASSET_COLLECTION } from '../common/Constants';
import { login } from './LoginService'
import './Login.css'
import { Navigate, useNavigate } from 'react-router-dom';
import { ManageLocalStorage } from '../core/LocalStorage';
import { ManageSessionStorage } from '../core/SessionStorage';

import LoginHeaderContainer from '../common/LoginHeaderContainer'
import FooterContainer from '../common/FooterContainer';


function LoginContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [username_error, setUserError] = useState("");
    const [password_error, setPasswordError] = useState("");
    const [error_msg, setErrorMsg] = useState()

    useEffect(() => {
    }, [])

    const clearValidation = () => {
        setUserError("");
        setPasswordError("");
    }

    const onGoToRegister = () => {
        _history(`${RENDER_URL.REGISTER_URL}`);
    }

    const onForgotClick = () => {
        _history(`${RENDER_URL.RESETPASSWORD_URL}`)
    }

    const onLoginButtonClick = () => {
        clearValidation();
        if (userName.length <= 0) {
            setUserError('Invalid Username');
        }

        else if (password.length < 8) {
            setPasswordError('Password must be at least 8 chars long');
        }
        else {
            clearValidation();
            loginApi();
        }
    }

    const loginApi = () => {

        dispatch({
            type: START_LOADING,
        });


        const loginData = {
            email: userName,
            password: password
        }

        login(loginData).then((response) => {

            if (response.data.success) {
                debugger;
                let userData = {
                    user_token: response.data.token,
                    uuid: response.data.uuid
                }

                let collection = response.data.avatarCollections
                let assetCollection = response.data.assetCollections
                ManageLocalStorage.set(ACDMY_COLLECTION, collection)
                ManageLocalStorage.set(ASSET_COLLECTION, assetCollection)


                dispatch({
                    type: SET_COLLECTION,
                    payload: collection,
                });

                dispatch({
                    type: SET_ASSET_COLLECTION,
                    payload: assetCollection,
                });

                ManageLocalStorage.set(USER_TOKEN, userData.user_token)
                ManageLocalStorage.set(USER_DATA, userData);

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: userData,
                });

                dispatch({
                    type: STOP_LOADING,
                });


                _history(RENDER_URL.HOME_URL);

            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.error);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }

    return (
        <>
            <LoginHeaderContainer></LoginHeaderContainer>
            <div className="container">
                <div className="row justify-content-center" style={{ marginRight: '0px', marginLeft: '0px' }}>
                    <div className="col-md-9 col-lg-12 col-xl-10" style={{ height: '620px', width: '577px', padding: '0px', paddingTop: '0px' }}>
                        <div className="card bg-dark shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="p-5" style={{ padding: '32px', height: '580px', paddingTop: '48px', background: '#f8f9fc', marginTop: '-16px', borderRadius: '6px' }}>
                                            <div className="row">
                                                <div className="col text-center" style={{ paddingTop: '0px' }}><img style={{ width: '100px', height: '90px', padding: 0 }} src="assets/img/Crown_Black.png" width={100} height={90} /></div>
                                            </div>
                                            <div className="row text-center">
                                                <div className="col" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                                    <h1 style={{ textAlign: 'center', fontFamily: 'ABeeZee, sans-serif', fontSize: '18px', fontWeight: 'bold', color: 'rgb(133, 135, 150)' }}>Enter Degen Royale</h1>
                                                </div>
                                            </div>
                                            <div className="text-center"></div>
                                            <form className="user" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                                <div className="mb-3">
                                                    <input className="form-control form-control-user" value={userName} onChange={(e) => { setUserName(e.target.value) }} type="email" id="InputEmail" aria-describedby="emailHelp" placeholder="Email" name="email" style={{ borderRadius: '5px', fontSize: '12px', padding: '12px', fontFamily: 'ABeeZee, sans-serif' }} />
                                                    <span className='error_msg'>{username_error}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <input className="form-control form-control-user" type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} id="InputPassword" placeholder="Password" name="password" style={{ borderRadius: '5px', fontSize: '12px', padding: '12px', fontFamily: 'ABeeZee, sans-serif' }} />
                                                    <span className='error_msg'>{password_error}</span>
                                                </div>
                                                {/*  <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="formCheck-1" />
                                                    <label className="form-check-label" htmlfor="formCheck-1" style={{ fontSize: '14px', fontFamily: 'ABeeZee, sans-serif' }}>Remember me</label>
                                                </div> */}
                                                <div className="mb-3">
                                                    <div className="custom-control custom-checkbox small"></div>
                                                </div>
                                                <div>
                                                    <span className='success_msg' style={{ padding: '10px', color: "red" }}>{error_msg}</span>
                                                </div>
                                                <button onClick={() => { onLoginButtonClick() }} className="btn d-block btn-user w-100" type="button" style={{ borderRadius: '5px', color: 'var(--bs-white)', fontSize: '16px', background: '#890aff', fontWeight: 'bold', padding: '12px 16px', fontFamily: 'ABeeZee, sans-serif', borderWidth: '0px' }}>Sign In</button>
                                                <hr></hr>
                                            </form>
                                            <button onClick={() => { onGoToRegister() }} className="btn btn-primary d-block btn-user w-100" type="submit" style={{ bsBodyFontSize: '11rem', bsBodyFontWeight: 'bold', borderRadius: '5px', background: 'var(--bs-orange)', fontSize: '14px', fontFamily: 'ABeeZee, sans-serif', borderWidth: '6px', borderStyle: 'none' }}>Create an account</button>
                                            <hr></hr>
                                            <div className="text-center" style={{ paddingTop: '10px', fontFamily: 'ABeeZee, sans-serif', cursor: 'pointer' }}>
                                                <a onClick={() => { onForgotClick() }} style={{ fontSize: '12px', borderColor: 'var(--bs-gray-500)', color: 'var(--bs-secondary)' }}>Forgot Password</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContainer></FooterContainer>
        </>
    );
}

export default LoginContainer;