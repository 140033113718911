import React from "react"
import {useMemo} from "react"
import { GlobalDispatchContext, GlobalContext } from "./Context"
import { globalReducer, initialGlobal } from "../reducers/CombineReducer"
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
// import the styles
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
require('@solana/wallet-adapter-react-ui/styles.css');

const ContextStore = (props) => {
    const [globalState, dispatch] = React.useReducer(globalReducer, initialGlobal);
    const states = { ...globalState };
    // you can use Mainnet, Devnet or Testnet here
    const solNetwork = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
    // initialise all the wallets you want to use
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            // new GlowWalletAdapter(),
            // new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ solNetwork }),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            // new SolletExtensionWalletAdapter(),
            // new SolletWalletAdapter(),
        ],
        [solNetwork]
    );
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets}>
                <WalletModalProvider>
                    <GlobalContext.Provider value={states}>
                        <GlobalDispatchContext.Provider value={dispatch}>{props.children}</GlobalDispatchContext.Provider>
                    </GlobalContext.Provider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );

}
export default ContextStore