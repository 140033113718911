import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect } from 'react';

const SolanaConnectButton = ({isVerified, setData}) => {
    const { connection } = useConnection();
    const { publicKey, connected } = useWallet();
    const handleConnectionChange = (c,w) => {
        console.log(`handleConnectionChange`);
        setData(c,w);
    };
    useEffect(() => {
        if (connected) {
            console.log(`Connection state is : `, connected);
            console.log(`Wallet address is : `, publicKey.toString());
            console.log(`type of setData : `, typeof setData);
            // handleConnectionChange(connected, publicKey.toString())
            setData(connected, publicKey.toString());
        }
    }, [connected])
    return (
    	<div>
            <WalletMultiButton disabled={!isVerified}/>
        </div>
    )
}

export default SolanaConnectButton