import { httpCall,httpFormCall, httpLoginCall } from "../core/HttpService";


export const twitterMapApi = params => {
    return httpCall({
        url: '/api/v1/twitter/usermap',
        method: "POST",
        data: params
    }).then(response => {
        return response;
    });
};