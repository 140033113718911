import { httpCall,httpFormCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const reset = params => {
    return httpLoginCall({
        url: API_URL.RESET_GUEST,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

