import React, { useState, useEffect } from 'react';
import { GlobalDispatchContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING } from '../common/Constants';
import { reset } from './ForgotService'
import { Navigate, useNavigate } from 'react-router-dom';
import { ManageLocalStorage } from '../core/LocalStorage';

import LoginHeaderContainer from '../common/LoginHeaderContainer'
import FooterContainer from '../common/FooterContainer';


function ForgotContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();

    const [userName, setUserName] = useState("");
    const [username_error, setUserError] = useState("");
    const [error_msg, setErrorMsg] = useState()
    const [success_msg, setSuccessMsg] = useState()


    const clearValidation = () => {
        setUserError("");
        setSuccessMsg("");
        setErrorMsg("");
    }

    const onGoToRegister = () => {
        _history(`${RENDER_URL.REGISTER_URL}`);
    }

    const onGoToLogin = () => {
        _history('/');
    }


    const onResetButtonClick = () => {
        clearValidation();
        if (userName.length <= 0) {
            setUserError('Email cannot be empty');
        }
        else if (!validateEmail(userName)) {
            setUserError('Invalid Email');
        }
        else {
            clearValidation();
            resetApi();

        }
    }

    const resetApi = () => {

        dispatch({
            type: START_LOADING,
        });

        const resetData = {
            email: userName,
        }

        reset(resetData).then((response) => {

            if (response.data.success) {

                dispatch({
                    type: STOP_LOADING,
                });

                /*  let resetToken = response.data.data;
                let path = RENDER_URL.CHANGE_PASSWORD + '?code='+resetToken;
                _history(path); */
                setSuccessMsg(response.data.message);

            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.error);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }

    return (
        <>
            <LoginHeaderContainer></LoginHeaderContainer>
            <div className="container">
                <div className="row justify-content-center" style={{ marginRight: '0px', marginLeft: '0px' }}>
                    <div className="col-md-9 col-lg-12 col-xl-10" style={{ height: '501px', width: '577px', padding: '0px', paddingTop: '0px' }}>
                        <div className="card bg-dark shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="p-5" style={{ padding: '32px', height: '434px', paddingTop: '48px', background: '#f8f9fc', marginTop: '-16px', borderRadius: '6px' }}>
                                            <div className="row">
                                                <div className="col text-center" style={{ paddingTop: '0px' }}><img style={{ width: '100px', height: '90px', padding: 0 }} src="assets/img/Crown_Black.png" width={100} height={90} /></div>
                                            </div>
                                            <div className="row text-center">
                                                <div className="col" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                                    <h1 style={{ textAlign: 'center', fontFamily: 'ABeeZee, sans-serif', fontSize: '18px', fontWeight: 'bold', color: 'rgb(133, 135, 150)' }}>Forgot Password</h1>
                                                </div>
                                            </div>
                                            <div className="text-center"></div>
                                            <div className="user" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                                <div className="mb-3">
                                                    <input value={userName} onChange={(e) => { setUserName(e.target.value) }} className="form-control form-control-user" type="email" id="InputEmail" aria-describedby="emailHelp" placeholder="Email" name="email" style={{ borderRadius: '5px', fontSize: '12px', padding: '12px', fontFamily: 'ABeeZee, sans-serif' }} />
                                                    <span className='error_msg'>{username_error}</span>
                                                </div>
                                                <button onClick={() => { onResetButtonClick() }} className="btn d-block btn-user w-100" type="button" style={{ borderRadius: '5px', color: 'var(--bs-white)', fontSize: '16px', background: '#0d6efd', fontWeight: 'bold', padding: '12px 16px', fontFamily: 'ABeeZee, sans-serif', borderWidth: '0px' }}>Reset Password</button>
                                                <span className='error_msg'>{error_msg}</span>
                                                <span className='success_msg'>{success_msg}</span>

                                                <hr></hr>
                                            </div>
                                            <button onClick={() => { onGoToLogin() }} className="btn btn-primary d-block btn-user w-100" type="button" style={{ bsBodyFontSize: '11rem', bsBodyFontWeight: 'bold', borderRadius: '5px', background: '#890aff', fontSize: '14px', fontFamily: 'ABeeZee, sans-serif', borderWidth: '6px', borderStyle: 'none' }}>Go to sign up</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContainer></FooterContainer>
        </>
    );
}

export default ForgotContainer;