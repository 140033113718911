import React, { useState, useEffect, useRef } from "react";
import HeaderContainer from "../common/HeaderContainer";
import HeaderNew from "../common/HeaderNew";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { GlobalContext, GlobalDispatchContext } from "../context/Context";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import NewsPopup from "./NewsPopup";
import { TabView, TabPanel } from 'primereact/tabview';
import TwitterButtonComponent from "./TwitterButtonComponent";
import { dashboardAttributes, getLatestPinnedTweet, likeClaimCHeck, retweetClaimCheck } from "./HomeService";
import { START_LOADING, STOP_LOADING, FROM_DASHBOARD, RENDER_URL, LOGOUT, TWITTER_USER } from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage";
import { ManageSessionStorage } from "../core/SessionStorage";


function HomenewContainer(props) {

    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext);
    const _history = useNavigate();

    const mytwitterRef = useRef(null);
    const [twitterUser, setTwitterUser] = useState()
    const [twitterConnected, setTwitterConnected] = useState(false);
    const [tweet_id, setLatestTweetID] = useState(undefined);

    const [mysteryAvatarList, setMysteryAvatarList] = useState([])

    const [socialList, setSocialList] = useState([])
    const [mysteryAvatar, setMysteryAvatar] = useState()


    const [showProfileImage, setProfileImage] = useState(true);

    const [currentCount, setCurrentCount] = useState(0);

    const [twitterConnectCoins, setTwitterConnectCoins] = useState(0);
    const [retweetCoins, setRetweetCoins] = useState(0);
    const [tweetLikeCoins, setTweetLikeCoins] = useState(0);


    useEffect(() => {
        setTwitterUser(context.login.TWITTER_USER)
    }, [context.login.TWITTER_USER]);




    useEffect(() => {
        if (context.login.userData) {
            loadMyAvatars();
            loadLatestTweet();
        }

    }, []);


    useEffect(() => {

        socialList.forEach(element => {
            if (element.action == "CONNECT") {
                setTwitterConnected(true);
                setTwitterConnectCoins(element.coins);
                dispatch({
                    type: TWITTER_USER,
                    payload: { id: element.uuid },
                });
            }
            else if (element.action == "RETWEET") {
                setRetweetCoins(element.coins);
            }
            else if (element.action == "LIKE") {
                setTweetLikeCoins(element.coins);
            }
        })

    }, [socialList])


    const loadLatestTweet = () => {
        let data = {
            tuser_id: process.env.REACT_APP_DEGEN_TWITTER_ID
        }
        getLatestPinnedTweet(JSON.stringify(data)).then((response) => {
            if (response.data.success) {
                setLatestTweetID(response.data.data.pinned_tweet_id);
            }
        }).catch((error) => {
            console.log("TWEET DATA ERROR ", error);
        })
    }


    const loadMyAvatars = () => {

        dispatch({
            type: START_LOADING,
        });

        dashboardAttributes().then((response) => {

            if (response.data.success) {
                let avatarData = response.data.attributes.avatars;
                let socialData = response.data.attributes.social;

                if (socialData.length == 0) {
                    setTwitterUser();
                }
                else {
                    setSocialList(socialData);

                }

                if (avatarData) {
                    setMysteryAvatarList(avatarData);
                    setCurrentCount(0);
                    setMysteryAvatar(avatarData[0]);
                }
            }

            dispatch({
                type: STOP_LOADING,
            });

        }).catch((error) => {
            console.log(error, "ERROR");
            dispatch({
                type: STOP_LOADING,
            });
        })

    }





    const checkRetweetClaim = () => {
        let data = {
            "tuser_id": twitterUser.id,
            "tweet_id": tweet_id
        }
        retweetClaimCheck(JSON.stringify(data)).then((response) => {
            console.log("response", response);
            if (response.data.success) {
                loadMyAvatars();
            } else {
                response.data.message = "Challenge not Completed";
                alert(response.data.message)
            }
        }).catch((error) => {
            console.log("error", error);
        })
    }
    const checkLikeClaim = () => {
        let data = {
            "tuser_id": twitterUser.id,
            "tweet_id": tweet_id
        }
        likeClaimCHeck(JSON.stringify(data)).then((response) => {
            console.log("response", response);
            if (response.data.success) {

                loadMyAvatars();

            } else {
                response.data.message = "Challenge not completed";
                alert(response.data.message)
            }
        }).catch((error) => {
            console.log("error", error);
        })
    }

    const followUsTwitter = () => {

        let data = {
            "tuser_id": twitterUser.twitterProvider.id,
        }
        retweetClaimCheck(JSON.stringify(data)).then((response) => {
            console.log("response", response);
            if (response.data.success) {
                alert(response.data.message)
            } else {
                alert(response.data.message)
            }
        }).catch((error) => {
            console.log("error", error);
        })

    }

    const connectTwitter = () => {
        mytwitterRef.current.click();
    }

    const renderEarnedCoins = () => {
        let count = 0;

        if (socialList.length > 0) {

            socialList.forEach(element => {
                count = count + element.coins
            })

            return count;
        }
        return count;
    }





    const [visible, setVisible] = useState(false);


    const [selectedNews, setSelectedNews] = useState({
        "id": 9,
        "content": "We do not know when and where the Knittables were seen first… Tales, passed down from one generation to the next, suggest that their discovery must reach back to the earliest days of mankind.\n\nSome say they are barely taller than a mouse. Some say they are as tall as a puppy. One thing is for certain, they exist and have been seen on many different occasions. Some worship and consider them as holy creatures, whispering their prayers through the night. Others fear them and believe that they are evil beings, flinching in horror when they hear about their yarns. Could that be because of the way they look? Nobody knows for sure, as they all seem to be unambiguously different.\nTo this day, it is not clear where the Knittables really came from and what their purpose might be. Legend has it that they were born from connections between souls and that their bodies, and the tight-knit thread that composes them, solely rely on an intangible strong inner bond originating from inseparable soul connections.\n\nThe elders agree that their story must have started on earth and their sightings encompass locations ranging from the deepest forests, highest mountains, and driest deserts to raging oceans all over our planet.\n\nCurrently, we are working on integrating the entire Knittables collection to enable you to play with your favorite Knittables as well! ????",
        "addTime": "2023-03-29T05:14:38.000Z",
        "lastModified": "2023-03-29T05:14:45.000Z",
        "publishedTime": "2023-03-29T05:14:45.000Z",
        "title": null,
        "imageURL": null
    });

    const [newsPopupVisible, setNewsPopupVisible] = useState(false);

    const onNewsClick = () => {
        setNewsPopupVisible(true);

        console.log("clicked");
    }
    return (
        <>
            <div className="home_section">
                <HeaderNew active={RENDER_URL.HOMENEW_URL}></HeaderNew>

                <section>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-sm-4 div_col" onClick={() => { onNewsClick() }}>
                                <h4 className="pos" style={{ color: 'white', bottom: '30px' }}>News</h4>
                                <p className="pos" style={{ color: 'white', bottom: '5px' }}> Interverse partners with xyz</p>
                                <NewsPopup newsData={selectedNews} newsVisible={newsPopupVisible} />
                            </div>
                            <div className="col-sm-4 ">
                                <div className="row">
                                    <div className="col-sm-4 div_col1">
                                        <h4 className="pos" style={{ color: 'white', bottom: '30px' }}>Shop</h4>
                                        <p className="pos" style={{ color: 'white', bottom: '5px' }}> Announce new gun or item</p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 div_col2">
                                        <h4 className="pos" style={{ color: 'white', bottom: '30px' }}>Release notes</h4>
                                        <p className="pos" style={{ color: 'white', bottom: '5px' }}> Version number xxxxx</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-sm-6">
                                <div className="col-sm-12" style={{ marginTop: '40px'}}>
                                    <TabView>
                                        <TabPanel header="Quests">
                                            <div className="task_list" style={{ minHeight: '350px' }}>

                                                {twitterConnected ?
                                                    <div className="row">
                                                        <div className="col-lg-8 col-sm-8">
                                                            <h5 className="task_desc"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> Connected</h5>
                                                            <p style={{ marginLeft: '8%' }}>
                                                                <span className="task_desc">Earn 20 coins by connecting twitter accounts</span>
                                                            </p>
                                                        </div>
                                                        <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                            <p className="task_desc"><b>{twitterConnectCoins} coins</b></p>
                                                            <input type="button" className="btn btn-sm btn-secondary task_desc" value="Connected" disabled />

                                                        </div>
                                                        <hr className="mt-2"></hr>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="row">
                                                            <div className="col-lg-8 col-sm-8">
                                                                <h5 className="task_desc"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> Connect</h5>
                                                                <p style={{ marginLeft: '8%' }}>
                                                                    <span className="task_desc">Earn 20 coins by connecting twitter accounts</span></p>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                                <TwitterButtonComponent />
                                                            </div>
                                                            <hr className="mt-2"></hr>
                                                        </div>
                                                    </>
                                                }

                                                <div className="row">
                                                    <div className="col-lg-8 col-sm-8">
                                                        <h5 className="task_desc"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> Twitter Retweets</h5>
                                                        <p style={{ marginLeft: '8%' }}>
                                                            <a href={`https://twitter.com/Degen_Royale/status/${tweet_id}`} className="challenge_desc">Earn 10 coins for retweeting a recent tweet from the DEGEN ROYALE Twitter account (Every 24 hours)</a>
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                        <p className="task_desc"><b>{retweetCoins} coins</b></p>
                                                        <input type="button" className="btn btn-sm btn-secondary  task_desc" value="Claim" onClick={() => { checkRetweetClaim() }} />
                                                    </div>
                                                    <hr className="mt-2"></hr>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-8 col-sm-8">
                                                        <h5 className="task_desc"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> Like this tweet</h5>
                                                        <p style={{ marginLeft: '8%' }}>
                                                            <a href={`https://twitter.com/Degen_Royale/status/${tweet_id}`} className="challenge_desc">Like the tweets on our twitter page DEGEN ROYALE</a>
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                        <p className="task_desc"><b>{tweetLikeCoins} coins</b></p>
                                                        <input type="button" className="btn btn-sm btn-secondary task_desc" value="Claim" onClick={() => { checkLikeClaim() }} />
                                                    </div>
                                                    <hr className="mt-2"></hr>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabView>

                                </div>
                            </div>

                        </div>
                        <div className="row mt-1">
                            <div className="col-sm-6">
                                <p className="p-2 final_coin text-end">Coins earned : {renderEarnedCoins()}</p>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default HomenewContainer