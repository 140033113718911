import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useHref } from 'react-router-dom';
import { RENDER_URL, LOGOUT } from './Constants';
import { START_LOADING, STOP_LOADING } from '../common/Constants';
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { ProgressSpinner } from 'primereact/progressspinner';
import { ManageSessionStorage } from "../core/SessionStorage";
import { ManageLocalStorage } from "../core/LocalStorage";
import './Loader.css'
import { Dropdown } from 'primereact/dropdown';

function HeaderNew(props) {

    console.log("props", props.active);

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)
    const showLoader = context.common.isLoading;
    const _history = useNavigate();
    const location = useLocation();

    const [selectedPath, setSelectedPath] = useState();


    const _links = [
        { name: 'COLLECTABLES', code: `${RENDER_URL.HOME_URL}` },
        { name: 'WALLET', code: `${RENDER_URL.WALLET}` },
        { name: 'LEADERBOARD', code: `${RENDER_URL.LEADER_BOARD}` },
        { name: 'QUESTS', code: `${RENDER_URL.HOMENEW_URL}` },
        { name: 'INVITE', code: `${RENDER_URL.INVITE_URL}` },
        { name: 'LOGOUT', code: `` }
    ];

    useEffect(() => {
        if (selectedPath) {
            if (selectedPath.name == "LOGOUT") {
                clearBrowser();
            }
            else {
                if (location.pathname != selectedPath.code) {
                    console.log(location.pathname, selectedPath.code);
                    _history(selectedPath.code);
                }
            }
        }
    }, [selectedPath])

    const getName = (path) => {

        let name = _links.filter((element) => {
            if (element.code == path) {
                return true
            }
        })

        if (name.length > 0) {
            return name[0].name;
        }
    }

    const clearBrowser = (e) => {

        if (e)
            e.preventDefault();

        dispatch({
            type: LOGOUT,
        });

        ManageLocalStorage.clear();
        ManageSessionStorage.clear();



    }

    const path = window.location.pathname

    return (
        <>
            {showLoader && <div className='sample_loader'>
                <div className='loader_center'>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>}

            <nav className="navbar fill react_nav">
                <div className="container-fluid">
                    <div className="row nav_row">
                        <div className='col-auto text-center'>
                            <div className='logo_div'>
                                <img className='logo_img' alt='@' src='./assets/img/Whitelogo.png' />
                            </div>
                        </div>
                        <div className="col d-none d-lg-block d-xl-block">

                            <Link to={RENDER_URL.HOME_URL} className={props.active == RENDER_URL.HOME_URL ? "a btn btn-color active_hover" : "a btn btn-color"} type="button" >COLLECTABLES</Link>
                            <Link to={RENDER_URL.WALLET} className={props.active == RENDER_URL.WALLET ? "a btn btn-color active_hover" : "a btn btn-color"} type="button" >WALLET</Link>
                            <Link to={RENDER_URL.LEADER_BOARD} className={props.active == RENDER_URL.LEADER_BOARD ? "a btn btn-color active_hover" : "a btn btn-color"} type="button" >LEADERBOARD</Link>
                            <Link to={RENDER_URL.HOMENEW_URL} className={props.active == RENDER_URL.HOMENEW_URL ? "a btn btn-color active_hover" : "a btn btn-color"} type="button">QUESTS</Link>
                            <Link to={RENDER_URL.INVITE_URL} className={props.active == RENDER_URL.INVITE_URL ? "a btn btn-color active_hover" : "a btn btn-color"} type="button">INVITE</Link>
                            {/* <Link className="a btn btn-color" type="button">SETTINGS</Link> */}
                            <Link className="a btn btn-color" type="button" onClick={clearBrowser}>LOGOUT</Link>
                        </div>
                        <div className='col d-sm-none'>

                            <Dropdown value={selectedPath} onChange={(e) => setSelectedPath(e.value)} options={_links} optionLabel="name"
                                placeholder={getName(location.pathname)} className="w-full md:w-14rem" />

                        </div>
                    </div>
                </div>

            </nav>



        </>
    )

}

export default HeaderNew