//routing files
import React, { Suspense } from "react";
import { RENDER_URL } from "../common/Constants";
import ContextStore from "../context/ContextStore"
import RootRouteGuard from "./RootRouteGuard"
import { BrowserRouter, Routes, Route, } from "react-router-dom";

const _Routes = ({ history }) => {

    return (
        <BrowserRouter basename="/" history={history}>
            <Suspense fallback={<div className="displayNone"></div>}>
                <ContextStore>
                        <RootRouteGuard history={history}></RootRouteGuard>
                </ContextStore>
            </Suspense>
        </BrowserRouter >
    );
};

export default _Routes;