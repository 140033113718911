import React, { useEffect, useState, useRef } from 'react';
import HeaderNew from "../common/HeaderNew";
import { TabView, TabPanel } from 'primereact/tabview';
import { RENDER_URL, START_LOADING, STOP_LOADING } from "../common/Constants";
import 'primeicons/primeicons.css';
import { Button } from "primereact/button";
import { getUserAttributes } from '../home/HomeService';
import { GlobalDispatchContext } from "../context/Context"
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';


function InviteContainer() {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();
    const toast = useRef(null);

    const pTagRef = useRef("");
    const [myInviteCode, setMyInviteCodes] = useState([]);
    const [textBoxValue, setTextBoxValue] = useState("");
    const [showNoData, setShowNoData] = useState(false);
    const [showData, setShowData] = useState("");


    useEffect(() => {
        loadInviteCodes();
    }, []);

    const loadInviteCodes = () => {
        dispatch({
            type: START_LOADING
        })

        getUserAttributes().then((result) => {
            if (result.data.attributes.verified == false) {
                setShowNoData(true);
            }
            if (result.data.attributes) {
                if (result.data.attributes.inviteCodes.length > 0) {
                    setShowData(true);
                    setShowNoData(false);
                    setMyInviteCodes(result.data.attributes.inviteCodes);
                }
                dispatch({
                    type: STOP_LOADING,
                });

            }
        }).catch((error) => {
            console.log("error", error);
            //showError(error);
            dispatch({
                type: STOP_LOADING,
            });
        })
    }

    const showSuccess = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: `${message}`, life: 3000 });
    }

    const showError = (errormsg) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: `${errormsg}`, life: 3000 });
    }


    const copyToClipboard = async (valueToBeCopied) => {
        try {
            await navigator.clipboard.writeText(valueToBeCopied);
            showSuccess("Copied to clipboard");
        } catch (err) {
            console.error('Failed to copy: ', err);
        }

    };


    const renderInviteCode = () => {

        return Object.entries(myInviteCode).map(([index, value]) => {
            return <div className="row">
                <div className="col-lg-8 col-sm-8">
                    <p style={{ marginLeft: '8%' }}>
                        <p style={{ color: 'white' }} >{value.code}</p>
                    </p>
                </div>
                <div className="col-lg-4 col-sm-4 center-cell text-center">
                    {!value.claimed ?
                        <Button className=" button-sm p-button-secondary" disabled={value.claimed} onClick={() => { copyToClipboard(value.code) }}>
                            <i style={{ fontSize: '10px' }} className="pi pi-copy icon_decoration"></i></Button>
                        : <span>{value.claimed ? ('claimed') : ""}</span>
                    }
                </div>
                <hr className="mt-2"></hr>
            </div>

        })

    }


    return (
        <>
            <div className="home_section">
                <HeaderNew active={RENDER_URL.INVITE_URL}></HeaderNew>
                <section>
                    <div className="container">
                        <div className="row">
                            <Toast ref={toast} position="top-right" />
                            {showData && <div className="col-sm-6">
                                <div className="col-sm-12" style={{ marginTop: '40px' }}>
                                    <TabView>
                                        <TabPanel header="Invite Code">
                                            <div className="task_list" style={{ minHeight: '200px' }}>
                                                {renderInviteCode()}
                                            </div>
                                        </TabPanel>
                                    </TabView>
                                </div>
                            </div>
                            }
                            {
                                showNoData &&
                                <div className="row mt-6">
                                    <div className='col-lg-6 col-md-6 col-sm-12 offset-lg-3' >
                                        <div className="card">
                                            <Card>
                                                <p className="m-0 text-center">
                                                    <b>Please verify your email to earn invite codes</b>
                                                </p>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default InviteContainer