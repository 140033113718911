import { httpCall, httpFormCall, httpCallNormal, httpLoginCall, httpGetPublicSolana } from "../core/HttpService"
import { API_URL, SOLANA_API } from "../common/Constants"

let test = "https://public-api.solscan.io/account/tokens?account=2qJ4uAnbDVdMPgkdbLTL1K1PAUaEVxc5z1pF4gDJ2iUC";

export const getAllTokens = params => {
    return httpGetPublicSolana({
        url: "https://public-api.solscan.io/account/tokens",
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};

export const addPromoCode = params => {
    return httpFormCall({
        url: API_URL.PROMO_API,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const getTokenMeta = params => {
    return httpGetPublicSolana({
        url: "https://public-api.solscan.io/token/meta",
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};


export const getTokenMetaFromSolanaWeb3 = params => {
    return httpCallNormal({
        url: "https://cz82y8n8x8.execute-api.us-east-1.amazonaws.com/labs/interverse/solana",
        method: "POST",
        data: params
    }).then(response => {
        return response;
    });
}


export const getSolanaTokens = params => {
    return httpCall({
        url: API_URL.ETH_NFT_V2,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}

export const getEthereumTokens = params => {
    return httpCall({
        url: API_URL.ETH_NFT_V2,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}





export const removeAvatarApi = params => {
    return httpFormCall({
        url: API_URL.REMOVE_AVATAR,
        method: "delete",
        data: params
    }).then(response => {
        return response;
    });

}


export const removeAssetsApi = params => {
    return httpFormCall({
        url: API_URL.REMOVE_ASSETS,
        method: "delete",
        data: params
    }).then(response => {
        return response;
    });

}


export const sendEmailVerification = params => {
    return httpCall({
        url: API_URL.SEND_EMAIL_VERIFICATION,
        method: "get"
    }).then(response => {
        return response;
    });
}





export const getUserAttributes = params => {
    return httpCall({
        url: API_URL.ATTRIBUTE_USER_V2,
        method: "get"
    }).then(response => {
        return response;
    });
}


export const connectToGame = params => {
    return httpCall({
        url: API_URL.CONNECT_VERIFIED_AVATAR,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}

export const connectAssetToGame = params => {
    return httpCall({
        url: API_URL.CONNECT_VERIFIED_ASSETS,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}
