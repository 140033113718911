import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderContainer from '../common/HeaderContainer';
import HeaderNew from '../common/HeaderNew';
import './Shop.css'


function ShopContainer(props) {
    return (
        <>
            {/* <HeaderContainer></HeaderContainer> */}
            <HeaderNew></HeaderNew>
            <div className="container-fluid container_home">
                <div className='container'>
                    <div className="pagetitle" style={{ padding: '20px' }}>
                        <h1>Welcome to the NFT Marketplace</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">MarketPlace</li>
                            </ol>
                        </nav>
                    </div>

                    <section className="section shop">
                        <div className="row">
                            <div className='col-sm-12'>
                                <h4>Collections</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className='container p-5'>
                                <div className='row text-center'>
                                    <div className='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                                        <div class="card shop-card">
                                            <div className='top_banner'></div>
                                            <div className='collection_img'>
                                            <img src="https://api.sandbox.game/collections/177/logo?_=_" class="top_img_collection" alt="Cinque Terre"/>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">Degen Playboy</h5>
                                                <p class="card-text"><span>Playboy 6 Assets</span></p>
                                                <a href="#" class="btn btn-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                                        <div class="card shop-card">
                                            <div className='top_banner'></div>
                                            <div className='collection_img'>
                                            <img src="https://api.sandbox.game/collections/177/logo?_=_" class="top_img_collection" alt="Cinque Terre"/>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">Degen Playboy</h5>
                                                <p class="card-text"><span>Playboy 6 Assets</span></p>
                                                <a href="#" class="btn btn-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                                        <div class="card shop-card">
                                            <div className='top_banner'></div>
                                            <div className='collection_img'>
                                            <img src="https://api.sandbox.game/collections/177/logo?_=_" class="top_img_collection" alt="Cinque Terre"/>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">Degen Playboy</h5>
                                                <p class="card-text"><span>Playboy 6 Assets</span></p>
                                                <a href="#" class="btn btn-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                                        <div class="card shop-card">
                                            <div className='top_banner'></div>
                                            <div className='collection_img'>
                                            <img src="https://api.sandbox.game/collections/177/logo?_=_" class="top_img_collection" alt="Cinque Terre"/>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">Degen Playboy</h5>
                                                <p class="card-text"><span>Playboy 6 Assets</span></p>
                                                <a href="#" class="btn btn-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                                        <div class="card shop-card">
                                            <div className='top_banner'></div>
                                            <div className='collection_img'>
                                            <img src="https://api.sandbox.game/collections/177/logo?_=_" class="top_img_collection" alt="Cinque Terre"/>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">Degen Playboy</h5>
                                                <p class="card-text"><span>Playboy 6 Assets</span></p>
                                                <a href="#" class="btn btn-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    )
}

export default ShopContainer