import React, { useState, useEffect, useRef } from 'react';
import { GlobalDispatchContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING } from '../common/Constants';
import { validateVerificationCode, verification } from './VerificationService'
import './Verification.css'
import { Navigate, useNavigate } from 'react-router-dom';
import { ManageLocalStorage } from '../core/LocalStorage';
import LoginHeaderContainer from '../common/LoginHeaderContainer'
import FooterContainer from '../common/FooterContainer';
import { Toast } from 'primereact/toast';
import { useLocation } from "react-router-dom";

function VerificationContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [verificationCode, setVerificationCode] = useState(queryParams.size > 0 ? queryParams.get("token") : '');
    const [verification_error, setVerificationError] = useState("");
    const [error_msg, setErrorMsg] = useState()
    const [success_msg, setSuccessMsg] = useState()
    const toast = useRef(null);


    useEffect(() => {
        if (verificationCode != "")
            onVerifyButtonClick();

    }, [verificationCode]);

    const clearValidation = () => {
        setVerificationError("");
    }

    const onGoToLogin = () => {

        _history('/');

    }

    const onVerifyButtonClick = () => {
        clearValidation();
        let data = {
            token: verificationCode
        }
        validateVerificationCode(data).then((response) => {
            if (response.data.success) {
                onGoToLogin();
            }
        }).catch((error) => {
            alert("verification failed");
        })
    }

    return (
        <>
            <LoginHeaderContainer></LoginHeaderContainer>
            <div className="container">
                <Toast ref={toast} />
                <div className="row justify-content-center" style={{ marginRight: '0px', marginLeft: '0px' }}>
                    <div className="col-md-9 col-lg-12 col-xl-10" style={{ height: '620px', width: '577px', padding: '0px', paddingTop: '0px' }}>
                        <div className="card bg-dark shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="p-5" style={{ padding: '32px', height: '520px', paddingTop: '48px', background: '#f8f9fc', marginTop: '-16px', borderRadius: '6px' }}>
                                            <div className="row mt-6">
                                                <div className="col text-center" style={{ paddingTop: '0px' }}>
                                                    <img style={{ width: '100px', height: '90px', padding: 0 }} src="assets/img/Crown_Black.png" width={100} height={90} />
                                                </div>
                                            </div>
                                            <div className="row text-center">
                                                <div className="col" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                                    <h1 style={{ textAlign: 'center', fontFamily: 'ABeeZee, sans-serif', fontSize: '18px', fontWeight: 'bold', color: 'rgb(133, 135, 150)' }}>Enter Verification Code</h1>
                                                </div>
                                            </div>
                                            <div className="text-center"></div>
                                            <div className="user" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                                <div className="mb-3">
                                                    <input className="form-control form-control-user" type="text" value={verificationCode} onChange={(e) => { setVerificationCode(e.target.value) }} placeholder="enter your verification code here." style={{ borderRadius: '5px', fontSize: '12px', padding: '12px', fontFamily: 'ABeeZee, sans-serif' }} />
                                                    <span className='error_msg'>{verification_error}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="custom-control custom-checkbox small"></div>
                                                </div>
                                                <button className="btn d-block btn-user w-100" type="button" onClick={() => { onVerifyButtonClick() }} style={{ borderRadius: '5px', color: 'var(--bs-white)', fontSize: '16px', background: 'var(--bs-orange)', fontWeight: 'bold', borderWidth: '4px' }}>VERIFY</button>
                                                <span className='error_msg' style={{ padding: '10px', marginTop: '10px' }}>{error_msg}</span>
                                                <span className='success_msg'>{success_msg}</span>
                                                <hr />
                                            </div>
                                            {/* <button onClick={() => { onGoToLogin() }} className="btn btn-primary d-block btn-user w-100" type="submit" style={{ bsBodyFontSize: '11rem', bsBodyFontWeight: 'bold', borderRadius: '5px', background: '#890aff', fontSize: '14px', fontFamily: 'ABeeZee, sans-serif', borderWidth: '6px', borderStyle: 'none' }}>Go to sign in</button> */}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContainer></FooterContainer>
        </>
    );
}

export default VerificationContainer;