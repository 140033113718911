import {
    START_LOADING,
    STOP_LOADING,
    SET_RUMBLE_LOCAL,
    SET_COLLECTION
} from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage"
import { ManageSessionStorage } from "../core/SessionStorage";
export const commonInitialState = {

    localRumbleList: ManageSessionStorage.get("localRumbleList")
        ? JSON.parse(ManageSessionStorage.get("localRumbleList"))
        : [],
    latestArticles: ManageLocalStorage.get("latest")
        ? JSON.parse(ManageLocalStorage.get("latest"))
        : [],
    adds: ManageLocalStorage.get("adds")
        ? JSON.parse(ManageLocalStorage.get("adds"))
        : [],
    isLoading: false,
    isGuestAdd: false

};

export const commonReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return commonInitialState
    }
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }
        case SET_RUMBLE_LOCAL:
            ManageSessionStorage.set("localRumbleList", action.payload)
            return {
                ...state,
                localRumbleList: action.payload
            }
        default:
            return state;
    }
};