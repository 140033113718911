import { httpCall,httpFormCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const addWallet = params => {
    return httpCall({
        url: API_URL.WALLET_ADD,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};
export const addVerifiedWallet = params => {
    return httpCall({
        url: API_URL.WALLET_ADD_TRUST,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const getAddedWallets = params => {
    return httpCall({
        url: API_URL.WALLET_GET,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};


export const removeWallets = params => {
    return httpCall({
        url: API_URL.WALLET_REMOVE,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

export const getUserAttributes = params => {
    return httpCall({
        url: API_URL.ATTRIBUTE_USER_V2,
        method: "get"
    }).then(response => {
        return response;
    });
}

export const verifyEthWalletBio = wallet_token => {
    let the_data = {wallet_token:wallet_token}
    return httpCall({
        url: API_URL.WALLET_VERIFY,
        method: "post",
        data: the_data
    }).then(response => {
        return response;
    });
};

