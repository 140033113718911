export const ManageSessionStorage = {
    get(key) {
        if (!key) {
            return;
        }
        return sessionStorage.getItem(key);
    },
    set(key, data) {
        if (!key) {
            return;
        }
        data = (data) ? data : {};
        data = typeof data === "string" ? data : JSON.stringify(data);
        sessionStorage.setItem(key, data);
    },
    delete(key) {
        if (!key) {
            return
        }
        sessionStorage.removeItem(key);
    },
    clear() {
        sessionStorage.clear();
    }
};