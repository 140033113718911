import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import FooterContainer from '../common/FooterContainer';
import { addPromoCode, getEthereumTokens, connectToGame, getAllTokens, getTokenMeta, getUserAttributes, connectAssetToGame, getTokenMetaFromSolanaWeb3, getSolanaTokens, removeAvatarApi, removeAssetsApi, sendEmailVerification } from './HomeService';
import HeaderContainer from '../common/HeaderContainer';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GlobalDispatchContext } from "../context/Context"
import { STOP_LOADING, START_LOADING, ACDMY_COLLECTION, ASSET_COLLECTION, RENDER_URL, SUPPORTED_COINS_CODE, shortenWallet } from '../common/Constants';
import './Home.css'
import { ManageLocalStorage } from '../core/LocalStorage';
import { Toast } from 'primereact/toast';
import HeaderNew from '../common/HeaderNew';
import { getAddedWallets } from '../characterSelection/walletService';
import { Tooltip } from 'primereact/tooltip';


function HomeContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();
    const toast = useRef(null);

    const [showGuest, setGuestStatus] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [tokenList, setUserTokenList] = useState([]);
    const [userMetaTokenList, setMetaTokenList] = useState([]);
    const [userEthMetaTokenList, setEthMetaTokenList] = useState([]);
    const [connectedAvatars, setConnectedAvatarsList] = useState([]);
    const [connectedAssets, setConnectedAssets] = useState([]);
    const [showNFT, setNFTVisible] = useState(0);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayPromoBasic, setPromoDisplayBasic] = useState(false);
    const [displayVerifyBasic, setDisplayVerifyBasic] = useState(false);
    const [position, setPosition] = useState('center');

    const [autoScan, setAutoScan] = useState(false);

    const [walletErrorMsg, setWalletError] = useState("");
    const [promoAdded, setPromoAdded] = useState(true);
    const [promoCode, setPromoCode] = useState("");
    const [promoCodeErrorMsg, setPromoCodeErrorMsg] = useState("");
    const [userWalletList, setUserWalletList] = useState([]);
    const [walletScan, setWalletScanEnable] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState("");
    const [solNftReadErrorMsg, setsolNftReadErrorMsg] = useState("");
    const [ethNftReadErrorMsg, setethNftReadErrorMsg] = useState("");
    const SUPPORT_COLLECTIONS = [...JSON.parse(ManageLocalStorage.get(ACDMY_COLLECTION))]
    const SUPPORT_ASSET_COLLECTIONS = [...JSON.parse(ManageLocalStorage.get(ASSET_COLLECTION))]

    let _chainType = { id: "addnewwallet", address: "Add new wallet", chain: "" };

    useEffect(() => {
        loadConnectedToken();
        loadMyWallets();
    }, []);





    useEffect(() => {
        //loadMetaData()
        // loadMetaDataFromSolanaWeb3();
    }, [tokenList])

    useEffect(() => {
        if (autoScan) {
            onConnect();
        }
    }, [autoScan])

    useEffect(() => {
        try {
            // console.log(`selectedWallet : `, selectedWallet);
            
            if (selectedWallet?.address == _chainType.address) {
                _history(RENDER_URL.WALLET, { state: { showPop: false } });
            }
            // else if (!selectedWallet?.verified > 0) {
            //     _history(RENDER_URL.WALLET, { state: { showPop: true } });
            // }
        } catch (error) {
            console.log(error);
        }

    }, [selectedWallet])



    const validate = () => {

        setethNftReadErrorMsg('');
        setsolNftReadErrorMsg('');
        setWalletError('');
        if (!selectedWallet) {
            setWalletError("Enter wallet address")
            return false;
        }
        else if (selectedWallet.address.length <= 0) {
            setWalletError("Enter wallet address")
            return false;
        } else if (selectedWallet.address.length < 16) {
            setWalletError("Enter a valid wallet address")
            return false;
        }
        else {
            return true;
        }
    }

    const onClickVerify = () => {

        sendEmailVerification().then((response) => {
            if (response.data.success) {
                showSuccess(response.data.message);
            }
            else {
                showError(response.data.error);
            }
        }).catch((error) => {
            showError(error.message);
        })
    }

    const IsEthereum = () => {
        if (selectedWallet.address.startsWith("0x")) {
            return true;
        }
        else {
            return false;
        }
    }

    const onConnect = () => {

        if (validate()) {
            if (IsEthereum()) {
                loadEthereumTokens(selectedWallet.address)
            } else {
                loadSolanaTokens(selectedWallet.address)
                //loadToken(walletAddress);
            }
        }
    }

    const loadMyWallets = () => {

        getAddedWallets().then((response) => {
            if (response.data.success) {
                let chainType = response.data.data;
                chainType = chainType.filter(wlt=>{
                    return wlt.verified > 0;
                })
                chainType.push(_chainType);
                setUserWalletList(chainType);
                if (isVerified) { setWalletScanEnable(true); }
            }
        }).catch((error) => {
            console.log(error);
        })

    }


    const validatePromo = () => {
        if (promoCode == "") {
            setPromoCodeErrorMsg("Enter valid code");
            return false;
        }
        else {
            return true;
        }
    }

    const onPromoCode = () => {

        if (validatePromo()) {
            let x = {
                promo: promoCode
            }
            addPromoCode(x).then((response) => {
                console.log(response);
                if (response.data.success) {
                    setPromoAdded(true);
                    setGuestStatus(false);
                    setPromoCode("");
                    loadConnectedToken();
                    onHide('displayPromoBasic')
                    showSuccess("Promo Code Added");
                }
                else {
                    onHide('displayPromoBasic')
                    showError("Not a Valid Code");

                }
            }).catch((error) => {
                window.location.reload();
                console.log(error);
            })
        }

    }

    const showSuccess = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: `${message}`, life: 3000 });
    }

    const showError = (errormsg) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: `${errormsg}`, life: 3000 });
    }

    /* new mollaris API */
    const loadSolanaTokens = (_walletAddress) => {
        dispatch({
            type: START_LOADING,
        });
        const walletData = {
            wallet_token: _walletAddress
        }

        getSolanaTokens(walletData).then((result) => {
            console.log("SOLANA RESPONSE", result);

            if ((result.status == 200)) {
                let _temp = [];

                let data = result.data.data;
                let chain = data.chain;

                let assets = data.assets;
                let avatars = data.avatars;

                avatars.map((_metaData, index) => {
                    _metaData.isAvatar = true;
                    _metaData.code = _metaData.code;
                    _metaData.isConnected = false;
                    let alreadyPresent = connectedAvatars.filter((e) => {
                        let _name = _metaData.name
                        if (e.name == _name) {
                            return true
                        }
                    });
                    if (alreadyPresent.length <= 0) {
                        _temp.push(_metaData);
                    }
                })

                assets.map((_metaData, index) => {
                    _metaData.isAvatar = false;
                    _metaData.code = _metaData.code;
                    _metaData.isConnected = false;
                    let alreadyPresent = connectedAvatars.filter((e) => {
                        if (e.name == _metaData.name) {
                            return true
                        }
                    });
                    if (alreadyPresent.length <= 0) {
                        _temp.push(_metaData);
                    }
                })

                console.log("setting data", _temp);
                if (_temp.length <= 0) {
                    setsolNftReadErrorMsg('No Record Found');
                }
                setMetaTokenList(_temp);

                if (chain == SUPPORTED_COINS_CODE.SOLANO) {
                    console.log("Calling meta");
                    loadSolanaImages_Lazy(_temp);
                }
                dispatch({
                    type: STOP_LOADING,
                });

            }


        }).catch((error) => {
            console.log("SOLANA ERROR", error);
        })



    }

    const loadEthereumTokens = (_walletAddress) => {
        dispatch({
            type: START_LOADING,
        });
        const walletData = {
            wallet_token: _walletAddress
        }

        getEthereumTokens(walletData).then((result) => {

            if ((result.status == 200)) {
                let _temp = [];

                let data = result.data.data;

                let assets = data.assets;
                let avatars = data.avatars;

                if (assets.length <= 0 && avatars.length <= 0) {
                    showError('No Supported Collections');
                }

                avatars.map((_metaData, index) => {
                    _metaData.isAvatar = true;
                    _metaData.code = _metaData.code;
                    _metaData.isConnected = false;
                    let alreadyPresent = connectedAvatars.filter((e) => {
                        let _name = `${_metaData.collection} #${_metaData.token_id}`
                        if (e.name == _name) {
                            return true
                        }
                    });
                    if (alreadyPresent.length <= 0) {
                        _temp.push(_metaData);
                    }
                })

                assets.map((_metaData, index) => {
                    _metaData.isAvatar = false;
                    _metaData.code = _metaData.code;
                    _metaData.isConnected = false;
                    let alreadyPresent = connectedAvatars.filter((e) => {
                        if (e.name == _metaData.name) {
                            return true
                        }
                    });
                    if (alreadyPresent.length <= 0) {
                        _temp.push(_metaData);
                    }
                })

                console.log("setting data", _temp);

                if (_temp.length <= 0) {
                    setethNftReadErrorMsg('No Record Found');
                }

                setEthMetaTokenList(_temp);
                dispatch({
                    type: STOP_LOADING,
                });

            }
        }).catch((error) => {
            console.log("error", error);
            showError(error);
            dispatch({
                type: STOP_LOADING,
            });
        })

    }

    const loadConnectedToken = () => {
        dispatch({
            type: START_LOADING,
        });

        //get attributes
        getUserAttributes().then((result) => {
            if (result.data.attributes) {
                setIsVerified(result.data.attributes.verified);
                let _avatars = result.data.attributes.avatars;
                setConnectedAvatarsList(_avatars);
                let _assets = result.data.attributes.assets;
                setConnectedAssets(_assets);
                if (_avatars.length <= 0 && _assets.length <= 0) {
                    setGuestStatus(true);
                }
                dispatch({
                    type: STOP_LOADING,
                });

            }
        }).catch((error) => {
            console.log("error", error);
            showError(error);
            dispatch({
                type: STOP_LOADING,
            });
        })
    }

    //todo watch solono
    const loadSolanaImages_Lazy = async (solanaData) => {

        console.log("SOLA", solanaData);

        let apiPromises = [];
        solanaData.map(e => {
            const tokenData = {
                token: e.token_address
            }
            try {
                apiPromises.push(getTokenMetaFromSolanaWeb3(tokenData))
            } catch (error) {
                console.log("Error Handling Await");
            }
        })

        const promise_result = await Promise.all(apiPromises);
        const data = await Promise.all(promise_result.map((r, index) => {
            if (r) {
                let _image = r.data.metadata.image;
                solanaData[index].image = _image

                return r;
            }
        }))

        dispatch({
            type: START_LOADING,
        });

        console.log("AfterMeta SOl", solanaData);

        setMetaTokenList(solanaData);
        dispatch({
            type: STOP_LOADING
        });


    }

    const loadToken = (_walletAddress) => {

        dispatch({
            type: START_LOADING,
        });

        const walletData = {
            account: _walletAddress
        }

        getAllTokens(walletData).then((result) => {

            if ((result.status == 200)) {
                let tokenList = result.data.filter((e) => {
                    try {
                        if (e.tokenAmount) {
                            if (e.tokenAmount.amount > 0) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    } catch (error) {
                        dispatch({
                            type: STOP_LOADING,
                        });
                        return false;
                    }
                });
                setUserTokenList(tokenList);
            } else {
                dispatch({
                    type: STOP_LOADING,
                });
                showError(result.data.error.message);
            }

            //TODO
            //loadMetaData();
            //loadMetaDataFromSolanaWeb3();

        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            showError(error);
            //showError("Try after 30 seconds....");
            console.log("Error", error.message);
        })

    }



    const loadMetaDataFromSolanaWeb3 = async () => {

        let apiPromises = [];
        tokenList.map((e) => {
            const tokenData = {
                token: e.tokenAddress
            }
            try {
                apiPromises.push(getTokenMetaFromSolanaWeb3(tokenData))
            } catch (error) {
                console.log("Error Handling Await");
            }
        });

        const promise_result = await Promise.all(apiPromises);
        const data = await Promise.all(promise_result.map(r => {
            if (r) {
                return r.data.metadata;
            }
        }))
        console.log("RESULT NEW META", data);

        let _temp = [];
        if (data.length > 0) {
            data.map((_metaData, index) => {
                if (_metaData) {

                    SUPPORT_COLLECTIONS.map((collection) => {
                        if (collection.symbol && _metaData.symbol) {
                            if (collection.symbol == _metaData.symbol) {
                                _metaData.isAvatar = true;
                                _metaData.code = collection.code;
                                _metaData.isConnected = false;
                                _metaData.token_address = tokenList[index]
                                let alreadyPresent = connectedAvatars.filter((e) => {
                                    if (e.name == _metaData.name) {
                                        return true
                                    }
                                });

                                if (alreadyPresent.length <= 0) {
                                    _temp.push(_metaData);
                                }
                            }
                        }
                    })

                    SUPPORT_ASSET_COLLECTIONS.map((assetcollection) => {
                        if (assetcollection.symbol && _metaData.symbol) {
                            if (assetcollection.symbol == _metaData.symbol) {
                                _metaData.isAvatar = false;
                                _metaData.code = assetcollection.code;
                                _metaData.token_address = tokenList[index]
                                _metaData.isConnected = false;

                                let alreadyPresent = connectedAssets.filter((e) => {
                                    if (e.name == _metaData.name) {
                                        return true
                                    }
                                });

                                if (alreadyPresent.length <= 0) {
                                    _temp.push(_metaData);
                                }
                            }
                        }
                    })


                }
            })
            console.log("setting data", _temp);
            setMetaTokenList(_temp);
            dispatch({
                type: STOP_LOADING,
            });
            //onHide('displayBasic');
        }

    }


    const addGuestAccount = () => {
        dispatch({
            type: START_LOADING,
        });

        let params = {
            community: "DAA",
            token: "guest",
            owner:"guest"
        }

        connectToGame(params).then((result) => {

            dispatch({
                type: STOP_LOADING,
            });
            showSuccess(result.data.message);
            setGuestStatus(false);
            loadConnectedToken();

        }).catch((error) => {
            console.log("Error", error);
        })

    }

    const connectSolanaLocalNFTGameLive = (clickedNFTData) => {

        debugger;
        dispatch({
            type: START_LOADING,
        });

        console.log(clickedNFTData);
        try {

            let tokenNumber;

            var tokenNumberAfterHashSymbol = clickedNFTData.name.trim().match(/(# *[0-9]*)$/);
            if (tokenNumberAfterHashSymbol != null) {
                if (tokenNumberAfterHashSymbol.length > 0) {
                    tokenNumber = tokenNumberAfterHashSymbol[0];
                    tokenNumber = tokenNumber.replace("#", "");
                }
            }

            if (tokenNumber == undefined) {
                var tokenNumberAfterHashSymbol = clickedNFTData.name.trim().match(/([0-9]*)$/);
                if (tokenNumberAfterHashSymbol.length > 0) {
                    tokenNumber = tokenNumberAfterHashSymbol[0];
                }
            }

            let symbol = clickedNFTData.code;
            let isAvatar = clickedNFTData.isAvatar;
            //todo token address should be shared
            let tokenAddress = clickedNFTData.token_address;

            let params = {
                community: symbol,
                address: tokenAddress,
                token: tokenNumber,
                owner: selectedWallet?.address
            }
            console.log(`Selected Wallet is ${selectedWallet?.address}`);

            if (isAvatar) {
                //connect avatar api 
                connectToGame(params).then((result) => {

                    if (result.data.success) {
                        dispatch({
                            type: STOP_LOADING,
                        });
                        clickedNFTData.isConnected = true;
                        showSuccess(result.data.message);
                    } else {
                        dispatch({
                            type: STOP_LOADING,
                        });
                        showError(result.data.message);
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
            }
            else {
                //connect to asset api 
                let assetParams = {
                    nft_token: clickedNFTData.token_address,
                    token_id: tokenNumber,
                    symbol: symbol,
                    owner: selectedWallet?.address
                }
                console.log(`Selected Wallet is ${selectedWallet.address}`);
                
                connectAssetToGame(assetParams).then((result) => {

                    if (result.data.success) {
                        clickedNFTData.isConnected = true;
                        showSuccess(result.data.message);
                        dispatch({
                            type: STOP_LOADING,
                        });

                    } else {
                        dispatch({
                            type: STOP_LOADING,
                        });
                        showError(result.data.message);
                    }

                }).catch((error) => {
                    showError(error);
                })

            }
        } catch (error) {
            console.log("Error", error);
            dispatch({
                type: STOP_LOADING,
            });
        }
    }


    const connectETHLocalNFTGameLive = (clickedNFTData) => {

        debugger;
        dispatch({
            type: START_LOADING,
        });

        console.log(clickedNFTData);
        try {
            let tokenNumber = clickedNFTData.token_id
            let symbol = clickedNFTData.code;
            let isAvatar = clickedNFTData.isAvatar;
            //todo token address should be shared
            let tokenAddress = clickedNFTData.token_address;

            let params = {
                community: symbol,
                address: tokenAddress,
                token: tokenNumber,
                owner:selectedWallet?.address
            }
            console.log(`Selected Wallet is ${selectedWallet?.address}`);
            

            if (isAvatar) {
                //connect avatar api 
                connectToGame(params).then((result) => {

                    if (result.data.success) {
                        dispatch({
                            type: STOP_LOADING,
                        });

                        clickedNFTData.isConnected = true;
                        showSuccess(result.data.message);

                    } else {
                        dispatch({
                            type: STOP_LOADING,
                        });
                        showError(result.data.message);
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
            }
            else {
                //connect to asset api 
                let assetParams = {
                    nft_token: clickedNFTData.token_address,
                    token_id: tokenNumber,
                    symbol: clickedNFTData.code,
                    owner: selectedWallet?.address
                }
                console.log(`Selected wallet is ${selectedWallet?.address}`);
                
                connectAssetToGame(assetParams).then((result) => {

                    if (result.data.success) {
                        clickedNFTData.isConnected = true;
                        showSuccess(result.data.message);
                        dispatch({
                            type: STOP_LOADING,
                        });
                    }
                    else {

                        showError(result.data.message);
                        dispatch({
                            type: STOP_LOADING,
                        });
                    }



                }).catch((error) => {
                    dispatch({
                        type: STOP_LOADING,
                    });
                    showError(error);
                })

            }
        } catch (error) {
            console.log("Error", error);
        }


    }



    const dialogFuncMap = {
        'displayPromoBasic': setPromoDisplayBasic,
        'displayBasic': setDisplayBasic
    }

    const walletCheck = () => {
        if (userWalletList.length == 2) {
            //if wallet contain only one address the autofetch
            setSelectedWallet(userWalletList[0])
            setAutoScan(true);
        }
        onClick('displayBasic')
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        setPromoDisplayBasic(false); setPromoDisplayBasic(false);
        setDisplayBasic(false);
        if (name == "displayBasic") {
            //modal hiding reset 
            console.log("Something fishy");
            setSelectedWallet(null);
            setMetaTokenList([]);
            setEthMetaTokenList([]);
            loadConnectedToken();
        }

    }

    const renderTokenList = (tokenList) => {

        try {
            return (
                Object.entries(tokenList).map(([index, object]) => (
                    <div>{object.tokenAddress}</div>
                ))
            );

        } catch (error) {
            console.log("Null Object On rendering");
        }

    }

    const imageTab = (rowData) => {
        let url = "";
        if (rowData.url) {
            url = rowData.url;
        }
        else {
            url = rowData.image;
        }
        url = (url == undefined) ? "/assets/img/place_holder.png" : url
        return <img src={url} width='60px' height='60px' alt={url} onError={(e) => e.target.src = '/assets/img/place_holder.png'} />
    }

    const removeAvatar = (rowData) => {

        dispatch({
            type: START_LOADING,
        });

        let param = {
            "avatar": rowData.uuid
        }
        removeAvatarApi(param).then((response) => {
            console.log(response);
            if (response.data.success) {
                showSuccess(response.data.message);
                loadConnectedToken();

                dispatch({
                    type: STOP_LOADING,
                });
            }
        }).catch((error) => {
            console.log(error);
            dispatch({
                type: STOP_LOADING,
            });
        })


    }

    const removeAssets = (rowData) => {

        dispatch({
            type: START_LOADING,
        });

        let param = {
            "asset": rowData.uuid
        }
        removeAssetsApi(param).then((response) => {
            console.log(response);
            if (response.data.success) {
                showSuccess(response.data.message);
                loadConnectedToken();
                dispatch({
                    type: STOP_LOADING,
                });
            }
        }).catch((error) => {
            console.log(error);
            dispatch({
                type: STOP_LOADING,
            });
        })


    }


    const goToSteam = () => {

        setTimeout(() => {
            window.open('https://store.steampowered.com/app/2156070/Degen_Royale/', '_blank')
        }, 1000);

    }

    const buttonAvatarBody = (rowData) => {

        return <Button label='Remove' onClick={() => { removeAvatar(rowData) }} />

    }

    const buttonAssetBody = (rowData) => {

        return <Button label='Remove' onClick={() => { removeAssets(rowData) }} />

    }

    const nameTab = (rowData) => {
        return <div> {rowData.collection} #{rowData.token_id} </div>
    }

    const imageSolTab = (rowData) => {
        let url = "";
        if (rowData.url) {
            url = rowData.url;
        }
        else {
            url = rowData.image;
        }
        url = (url == undefined ? '/assets/img/place_holder.png' : url)

        return <img src={url} width='60px' height='60px' alt={url} onError={(e) => e.target.src = '/assets/img/place_holder.png'} />
    }

    const imageETHTab = (rowData) => {
        if (rowData.metadata != null) {
            let url = "";
            url = rowData.metadata.image.startsWith("ipfs://") ? `https://gateway.moralisipfs.com/ipfs/${rowData.metadata.image.split("ipfs://")[1]}` : rowData.metadata.image;
            return <img src={url} width='60px' height='60px' alt={rowData.url} onError={(e) => e.target.src = '/assets/img/place_holder.png'} />
        } else {
            return <img src='/assets/img/place_holder.png' width='60px' height='60px' alt={rowData.url} onError={(e) => e.target.src = '/assets/img/place_holder.png'} />
        }

    }

    const connectSolTemplate = (rowData) => {
        if (rowData.isConnected) {
            return <Button label='Linked' className="p-button-outlined p-button-success"></Button>;
        } else {
            return <Button label='Add to game' onClick={() => { connectSolanaLocalNFTGameLive(rowData) }}></Button>;
        }

    }

    const connectETHTemplate = (rowData) => {
        if (rowData.isConnected) {
            return <Button label='Linked' className="p-button-outlined p-button-success"></Button>;
        } else {
            return <Button label='Add to game' onClick={() => { connectETHLocalNFTGameLive(rowData) }}></Button>;
        }

    }


    const renderSupportedNft = () => {

        return Object.entries(userMetaTokenList).map(([index, object]) =>
        (
            <tr>
                <th scope="row">{index}</th>
                <td>{object.name}</td>
                <td>Designer</td>
            </tr>
        ))

    }


    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Read Wallet" onClick={() => onConnect()} autoFocus className='p-button-success' />
            </div>
        );
    }

    const renderPromoFooter = (name) => {
        return (

            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Add Code" onClick={() => onPromoCode()} autoFocus className='p-button-success' />
            </div>
        );
    }


    const dropdownItemTemplate =
        (option) => {
            return (
                option?.address == "Add new wallet" ?
                    <div className='col-lg-12 col-md-12 col-sm-12 text-left'><b style={{ color: 'green' }}>{option?.address}</b></div>
                    : <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-left'>{option?.address ? (option.address.length > 25 ? shortenWallet(option.address, 20, true) : option.address) : ""}</div>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-right'><b>{option?.chain.toUpperCase()}</b></div>
                        {option?.address && <div className='col-lg-4 col-md-4 col-sm-4 text-right'><b>{option?.verified ? "Verified" : "Not verified"}</b></div> }
                    </div>


            );
        };




    return (
        <>
            <div className="collectables_section">
                {/* <HeaderContainer></HeaderContainer> */}
                <HeaderNew active={RENDER_URL.HOME_URL}></HeaderNew>
                <div className="container-fluid container_home">
                    <div className='container'>

                        <Toast ref={toast} position="top-right" />


                        <Dialog header="Wallet Address" visible={displayBasic} style={{ width: '50vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                            <div className='container'>
                                <div className='row text-center mt-5'>
                                <label className='col-lg-12 col-md-12 col-sm-12 text-left'><b >Only verified wallets will be listed here</b></label>
                                    <div className='col-sm-12'>
                                        <Dropdown value={selectedWallet} onChange={(e) => setSelectedWallet(e.value)} options={userWalletList} optionLabel="address"
                                            placeholder="Select Wallet" valueTemplate={dropdownItemTemplate} itemTemplate={dropdownItemTemplate} className="form-control" />
                                        <span className='p-error' style={{ padding: '10px' }}>{walletErrorMsg}</span>
                                    </div>

                                    {userMetaTokenList.length > 0 ? <DataTable value={userMetaTokenList} responsiveLayout="scroll" scrollable scrollHeight="flex">
                                        <Column field="name" header="Name"></Column>
                                        <Column field="image" header="Image" body={imageSolTab}></Column>
                                        <Column field='name' header="Status" body={connectSolTemplate}></Column>
                                    </DataTable>
                                        :
                                        <div>{solNftReadErrorMsg}</div>
                                    }


                                    {userEthMetaTokenList.length > 0 ?
                                        <DataTable value={userEthMetaTokenList} responsiveLayout="scroll" scrollable scrollHeight="flex" >
                                            <Column field="name" header="Name" body={nameTab}></Column>
                                            <Column field="metadata" header="Image" body={imageETHTab}></Column>
                                            <Column field='name' header="Status" body={connectETHTemplate}></Column>
                                        </DataTable>
                                        :
                                        <div>{ethNftReadErrorMsg}</div>

                                    }

                                </div>
                            </div>
                        </Dialog>

                        <Dialog header="Promo Code" visible={displayPromoBasic} style={{ width: '50vw' }} footer={renderPromoFooter('displayPromoBasic')} onHide={() => onHide('displayPromoBasic')}>
                            <div className='container'>
                                <div className='row text-center mt-5'>
                                    <div className='col-sm-12'>
                                        <input type="text" value={promoCode} className='form-control' onChange={(e) => { setPromoCode(e.target.value) }} placeholder='Please enter promo code' />
                                        <span className='p-error' style={{ padding: '10px' }}>{promoCodeErrorMsg}</span>
                                    </div>
                                </div>
                            </div>
                        </Dialog>



                        {/* <div className="pagetitle" style={{ padding: '20px' }}>
                        <h1>Dashboard</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </nav>
                    </div> */}

                        <section className="section dashboard mt-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        {/* Sales Card */}
                                        {!isVerified && <div className="col-xxl-3 col-md-6">
                                            <div className="card customers-card sales-card">
                                                <div className="filter">
                                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots" /></a>
                                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li className="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title ps-3">E-mail Verification</h5>
                                                    <div className="d-flex align-items-center">
                                                        <div className="ps-3">
                                                            <Button className='p-button-success' label="Verify" onClick={onClickVerify} tooltip="Please verify your email" tooltipOptions={{ position: 'top' }} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        }
                                        <div className="col-xxl-3 col-md-6">
                                            <div className="card customers-card sales-card">
                                                <div className="filter">
                                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots" /></a>
                                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li className="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title ps-3">Add Avatars & Items</h5>
                                                    <div className="d-flex align-items-center">
                                                        {/* <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                            <i className="pi pi-wallet" />
                                                        </div> */}
                                                        <div className="ps-3">
                                                            <Button className='p-button-success' label="Add from wallet" disabled={!isVerified} onClick={() => walletCheck()} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>{/* End Sales Card */}
                                        {
                                            showGuest ? <div className="col-xxl-3 col-md-6">
                                                <div className="card customers-card sales-card">

                                                    <div className="card-body">
                                                        <h5 className="card-title ps-3">Add guest ape avatar <span>| Free</span></h5>
                                                        <div className="d-flex align-items-center">
                                                            <div className="ps-3">
                                                                <Button className='p-button-success' label="Add now" disabled={!isVerified} onClick={() => addGuestAccount()} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : <></>
                                        }

                                        {/* Sales Card */}
                                        {promoAdded ? <div className="col-xxl-3 col-md-6">
                                            <div className="card customers-card sales-card">
                                                <div className="filter">
                                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots" /></a>
                                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li className="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title ps-3">Promo Code</h5>
                                                    <div className="d-flex align-items-center">
                                                        {/* <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                            <i className="pi pi-wallet" />
                                                        </div> */}
                                                        <div className="ps-3">
                                                            <Button className='p-button-success' disabled={!isVerified} label="Enter promo Code" onClick={() => onClick('displayPromoBasic')} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div> : <></>
                                        }

                                        <div className="col-xxl-3 col-md-6">
                                            <div className="card customers-card sales-card">
                                                <div className="filter">
                                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots" /></a>
                                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li className="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title ps-3">Launch Game</h5>
                                                    <div className="d-flex align-items-center">
                                                        {/* <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                            <i className="pi pi-wallet" />
                                                        </div> */}
                                                        <div className="ps-3">
                                                            <Button className='p-button-success' disabled={!isVerified} label="Play Now" onClick={() => { goToSteam() }} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className="col-6">
                                    <div className="card recent-sales overflow-auto">
                                        <div className="card-body">
                                            <h5 className="card-title">Your available Avatars</h5>
                                            <DataTable value={connectedAvatars} responsiveLayout="scroll" scrollable scrollHeight="flex" emptyMessage="No Avatars connected">
                                                <Column field="name" header="Name"></Column>
                                                <Column field='url' header="Image" body={imageTab}></Column>
                                                <Column header="Remove" body={buttonAvatarBody}></Column>

                                            </DataTable>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="card recent-sales overflow-auto">
                                        <div className="card-body">
                                            <h5 className="card-title">Your Guns & Items</h5>
                                            <DataTable value={connectedAssets} responsiveLayout="scroll" scrollable scrollHeight="flex" emptyMessage="No Assets connected">
                                                <Column field="name" header="Name"></Column>
                                                <Column field='image' header="Image" body={imageTab}></Column>
                                                <Column header="Remove" body={buttonAssetBody}></Column>
                                            </DataTable>

                                        </div>
                                    </div>
                                </div>{/* End Recent Sales */}

                            </div>

                        </section>
                        <br></br>
                        <br></br>
                    </div>
                </div>

                <FooterContainer></FooterContainer>
            </div>
        </>
    );
}

export default HomeContainer;