import React, { useState, useEffect,useRef } from 'react';
import { GlobalDispatchContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING } from '../common/Constants';
import { changePassword } from './ChangePasswordService'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { ManageLocalStorage } from '../core/LocalStorage';
import LoginHeaderContainer from '../common/LoginHeaderContainer'
import FooterContainer from '../common/FooterContainer';
import { Toast } from 'primereact/toast';

function ChangePasswordContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    console.log("Code", code == null);

    const [password, setPassword] = useState("");
    const [password_error, setPasswordError] = useState("");

    const [error_msg, setErrorMsg] = useState()
    const [success_msg, setSuccessMsg] = useState()
    const toast = useRef(null);

    const clearValidation = () => {
        setPasswordError("");
        setErrorMsg("");
    }


    const onGoToLogin = () => {
        _history('/');
    }


    const onChangeClick = () => {
        clearValidation();
        if (password.length <= 0) {
            setPasswordError('Invalid Password');
        }
        else if (password.length <= 7) {
            setPasswordError('Password must be at least 8 chars long');
        }
        else {
            clearValidation();
            resetApi();
        }
    }

    const resetApi = () => {

        dispatch({
            type: START_LOADING,
        });


        const resetData = {
            reset_token: code,
            password: password,
        }

        changePassword(resetData).then((response) => {

            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });
                setPassword("");
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
                setSuccessMsg(response.data.message)
                
                _history(RENDER_URL.LOGIN_URL);
            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.error);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }

    if (code == null) {
        onGoToLogin("/");
    }

    return (
        <>
            <LoginHeaderContainer></LoginHeaderContainer>
            <div className="container">
            <Toast ref={toast} />
                <div className="row justify-content-center" style={{ marginRight: '0px', marginLeft: '0px' }}>
                    <div className="col-md-9 col-lg-12 col-xl-10" style={{ height: '501px', width: '577px', padding: '0px', paddingTop: '0px' }}>
                        <div className="card bg-dark shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="p-5" style={{ padding: '32px', height: '434px', paddingTop: '48px', background: '#f8f9fc', marginTop: '-16px', borderRadius: '6px' }}>
                                            <div className="row">
                                                <div className="col text-center" style={{ paddingTop: '0px' }}><img style={{ width: '100px', height: '90px', padding: 0 }} src="assets/img/Crown_Black.png" width={100} height={90} /></div>
                                            </div>
                                            <div className="row text-center">
                                                <div className="col" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                                    <h1 style={{ textAlign: 'center', fontFamily: 'ABeeZee, sans-serif', fontSize: '18px', fontWeight: 'bold', color: 'rgb(133, 135, 150)' }}>Change Password</h1>
                                                </div>
                                            </div>
                                            <div className="text-center"></div>
                                            <form className="user" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                                <div className="mb-3">
                                                    <input value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control form-control-user" type="password" id="input_password" aria-describedby="input_password" placeholder="New Password" name="password" style={{ borderRadius: '5px', fontSize: '12px', padding: '12px', fontFamily: 'ABeeZee, sans-serif' }} />
                                                    <span className='error_msg'>{password_error}</span>
                                                </div>
                                                <button onClick={(e) => { onChangeClick() }} className="btn d-block btn-user w-100" type="button" style={{ borderRadius: '5px', color: 'var(--bs-white)', fontSize: '16px', background: '#0d6efd', fontWeight: 'bold', padding: '12px 16px', fontFamily: 'ABeeZee, sans-serif', borderWidth: '0px' }}>Save Password</button>
                                                <span className='error_msg'>{error_msg}</span>
                                                <span className='success_msg'>{success_msg}</span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContainer></FooterContainer>
        </>
    );
}

export default ChangePasswordContainer;