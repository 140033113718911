import React, { Component } from 'react';
import { Link } from 'react-router-dom';


function FooterContainer(props) {

    return (

        <footer className="text-center py-4">
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-3 text-center">
                    <div className="col"></div>
                    <div className="col">
                        <p className="my-2" style={{ color: "rgb(255,255,255)", fontFamily: "ABeeZee, sans-serif" }}>Copyright&nbsp;© 2022 Interverse BV</p>
                        <ul className="list-inline my-2">
                            <li className="list-inline-item me-4">
                                <a
                                    href='https://store.steampowered.com/app/2156070/Degen_Royale/'
                                    className="bs-icon-circle bs-icon-primary bs-icon"
                                    style={{ background: "var(--bs-gray-100)" }}
                                >
                                    <i className="fa fa-steam" aria-hidden="true" style={{ 'fontSize': '1.2em', color: '#6f42c1' }}></i>
                                </a>
                            </li>
                            <li className="list-inline-item me-4">
                                <a
                                    href='https://twitter.com/Degen_Royale'
                                    className="bs-icon-circle bs-icon-primary bs-icon"
                                    style={{ background: "var(--bs-gray-100)" }}
                                >
                                    <i className="pi pi-twitter" style={{ 'fontSize': '1.2em', color: '#6f42c1' }}></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href='https://discord.gg/degen-royale'
                                    className="bs-icon-circle bs-icon-primary bs-icon"
                                    style={{ background: "var(--bs-gray-100)" }}
                                >
                                    <i className="pi pi-discord" style={{ 'fontSize': '1.2em', color: '#6f42c1' }}></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </footer>
    )

}

export default FooterContainer;