import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import LoginContainer from '../login/LoginContainer';
import RegisterContainer from '../register/RegisterContainer'
import ForgotContainer from "../forgot/ForgotContainer";
import { BUILD_VERSION, RENDER_URL, LOGOUT } from '../common/Constants'
import { httpInterceptor, setBaseUrl, setApplicationToken } from "../core/HttpService"
import { ManageLocalStorage } from "./LocalStorage";
import { ManageSessionStorage } from "./SessionStorage";
import { GlobalContext, GlobalDispatchContext } from "../context/Context";
import ChangePasswordContainer from "../password/ChangePasswordContainer";
import HomeContainer from "../home/HomeContainer";
import ExpandContainer from "../expand/ExpandContainer";
import ShopContainer from '../shop/ShopContainer';
import LeaderboardContainer from "../leaderboard/LeaderboardContainer";
import HomenewContainer from "../homenew/HomenewContainer";
import CharacterContainer from "../characterSelection/CharacterContainer";
import VerificationContainer from "../verification/VerificationContainer";
import InviteContainer from "../invites/inviteContainer";

const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)

    const clearBrowser = (e) => {
        dispatch({
            type: LOGOUT,
        });

        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
    }

    setBaseUrl(process.env.REACT_APP_BASE_URL);
    setApplicationToken(process.env.REACT_APP_TOKEN);
    httpInterceptor();


    const loginData = context.login;
    const _buildNo = ManageLocalStorage.get(BUILD_VERSION);
    console.log("buildNo", _buildNo);
    if (_buildNo == null || _buildNo != process.env.REACT_APP_BUILD_VERSION) {
        console.log("ENV", process.env.REACT_APP_BUILD_VERSION)
        clearBrowser(null);
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    } else {
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    }



    return (
        <Routes {...xProps}>
            <Route path={RENDER_URL.LOGIN_URL} exact element={loginData.isLoggedIn ? <Navigate to={RENDER_URL.HOME_URL} /> : <LoginContainer />} />
            <Route path={RENDER_URL.REGISTER_URL} exact element={<RegisterContainer />} />
            <Route path={RENDER_URL.RESETPASSWORD_URL} exact element={<ForgotContainer />} />
            <Route path={RENDER_URL.CHANGE_PASSWORD} exact element={<ChangePasswordContainer />} />
            <Route path={RENDER_URL.HOME_URL} exact element={loginData.isLoggedIn ? <HomeContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />} />
            <Route path={RENDER_URL.SHOP_URL} exact element={loginData.isLoggedIn ? <ShopContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />} />
            <Route path={RENDER_URL.ATTRIBUTE_URL} exact element={<ExpandContainer />} />
            <Route path={RENDER_URL.LEADER_BOARD} exact element={loginData.isLoggedIn ? <LeaderboardContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />} />
            <Route path={RENDER_URL.HOMENEW_URL} exact element={loginData.isLoggedIn ? <HomenewContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />} />
            <Route path={RENDER_URL.WALLET} exact element={loginData.isLoggedIn ? <CharacterContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />} />
            <Route path={RENDER_URL.INVITE_URL} exact element={loginData.isLoggedIn ? <InviteContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />} />
            <Route path={RENDER_URL.VERIFICATION} exact element={<VerificationContainer />} />
        </Routes>
    );
};
export default RootRouteGuard